import React from "react";
import { Layout, Container } from "~src/components";
import { Box } from "@rebass/grid/emotion";
import css from "@emotion/css";
import { colors, mq } from "~src/style";
import { Link } from "gatsby";

export default () => (
  <Layout>
    <Container>
      <Box
        css={css`
          max-width: 420px;
          a {
            color: ${colors.white};
          }
          margin: 16px;
          ${mq("lg")} {
            margin: 32px auto;
          }
        `}
      >
        <h1>Disclaimer</h1>
        <p>
          De woningmarktcijfers achter de interactieve kaart hebben we zo
          volledig mogelijk proberen te krijgen. Soms ontbreken waardes. Een
          uitleg waarom.
        </p>
        <p>
          <strong>Verkoopprijzen</strong>
          <br />
          De verkoopprijs is ‘onbekend’. Dit betekent dat in een bepaald gebied
          in een bepaald jaar minder dan tien woningen zijn verkocht. Omdat een
          gering aantal verkopen grote schommelingen kan veroorzaken, zijn deze
          gemiddelden niet opgenomen. De wijken Cool, Nieuw-Crooswijk, Heijplaat
          en het Witte Dorp zijn voorbeelden. Cijfers zijn afkomstig van de NVM;
          bewerking gemeente Rotterdam-OBI.
        </p>
        <p>
          <strong>WOZ-waardes</strong>
          <br />
          De WOZ-waarde is ‘onbekend’. Dit komt voor in gebieden met weinig
          woningen, waarvan de functie ook nog eens gemengd is (wonen plus
          werk). Dat zie je hoofdzakelijk op industrie- en bedrijventerreinen,
          zoals Nieuw-Mathenesse, Noord-Kethel en de Blijdorpsepolder. Cijfers
          zijn afkomstig van het CBS.
        </p>
        <p>
          <strong>Actieve partijen</strong>
          <br />
          Het jaar 2016 ontbreekt in zijn geheel. Waarom? De gemeente Rotterdam
          heeft deze dataset niet voorhanden. Selecteer daarom bij actieve
          partijen altijd een ander jaartal dan 2016 bij zowel begin- als
          eindpunt. Is 2016 een tussenliggend jaar in de vergelijking, dan geeft
          dat geen problemen. Cijfers zijn afkomstig van gemeente Rotterdam-OBI.
        </p>
        <p>
          <strong>Algemeen</strong>
          <br />
          Voor alle cijfers geldt: Rozenburg is pas sinds 2011 in de
          statistieken van de gemeente Rotterdam opgenomen. Eerder was het een
          zelfstandige gemeente. Charlois Zuidrand en Kop van Zuid
          (Wilhelminapier) zijn sinds 2012 een officiële CBS-buurt. Eerder
          maakten ze deel uit van andere buurten.
        </p>
        <p>
          <Link
            css={css`
              background: ${colors.white};
              padding: 16px 32px;
              color: ${colors.blue2} !important;
              font-weight: bold;
              text-decoration: none;
              margin: 32px 0;
              display: inline-block;
            `}
            to={`/app/`}
          >
            Terug naar de kaart
          </Link>
        </p>
      </Box>
    </Container>
  </Layout>
);
